// a library to wrap and simplify api calls
import apisauce from "apisauce"
// our "constructor"

const create = (baseURL = process.env.REACT_APP_MBRSHP_URL) => {

  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    timeout: 60000
  })


  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.

  const getMembershipUser = (userId) =>{
    return api.post("getStudentUserByIdForInstructor", {"userId": userId})

  }
  const checkMembershipUserStatus = (username) => {
    return api.post("getUserByUsername", {"username": username, "updateLastActivity": false})
  }
  const updateUserRole = (userId, role) => api.post("updateUserRole", {"userId": userId, "role": role})

  const changeEmail = (payload) => {

    const { username } = payload
    const { newEmail } = payload

   return  api.post("changeEmail", {"username": username, "newEmail": newEmail})
}
  const getAllUsers = () => {
    return api.post("allUsers", {})
  }

  const addNewUser = (payload) => {
    return api.post("setupNewUser", payload)
  }

  const adminResetPassword = (payload) => {
    return api.post("adminResetPassword", payload)
  }
  const bulkAddNewUsers = (payload) =>{
    return api.post("bulkSetupNewUsers",payload)
  }

  // ADD_API_HANDLER

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    getMembershipUser,
    checkMembershipUserStatus,
    updateUserRole,
    changeEmail,
    getAllUsers,
    addNewUser,
    bulkAddNewUsers,
    adminResetPassword
    // EXPORT_API_HANDLER
  }
}

// let's return back our create method as the default.
export default {create}
